import LogoImage from "../../../../assets/logo-middendelflandinbeeld.svg";
import Start from "../../../../assets/start.svg";
import BackImage from "../../../../assets/terug.svg";
import { useMediaQuery } from "react-responsive";

import "./Logo.scss";
function Logo(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const pageActive = props.status.page;

  if (isTabletOrMobile) {
    return (
      <div className="logo-wrapper-mobile" onClick={() => props.clickHandler()}>
        <img className="logo" src={LogoImage} alt="Logo" />
      </div>
    );
  }
  if (pageActive) {
    return (
      <>
        <div onClick={() => props.clickHandler()} className="logo-wrapper">
          <div className="back">
            <img src={BackImage} alt="Terug" />
            <span className="back-button-text">Terug</span>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="logo-wrapper" onClick={() => props.clickHandler()}>
      {!props.status.menuMain ? (
        <img className="logo" src={LogoImage} alt="Logo" />
      ) : (
        <div className="startscherm">
          <img src={Start} alt="Terug naar het startscherm" />
          <span className="back-button-text">Startscherm</span>
        </div>
      )}
    </div>
  );
}

export default Logo;
