import { useEffect, useState } from "react";
import Api from "../../../../../../services/Api";
import PlaceholderImage from "../../../../../../assets/Placeholder-Image.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import ReactPlayer from "react-player";

function Card(props) {
  const [pic, setPic] = useState([]);
  useEffect(() => {
    Api.fetchCard(props.tax, props.id).then((data) => {
      setPic(data.included.map((term) => term.attributes.uri));
    });
  }, [props.id]);
  let picArr = [];
  pic.map((pic) => {
    if (pic) {
      picArr.push(
        "https://redactie.middendelflandinbeeld.nl/files" + pic.url.slice(46)
      );
    }
  });

  return (
    <>
      <li
        className="card"
        onClick={() => props.cardBuilder(props.id, props.title, props.url)}
      >
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <h6 className="card-subtitle">{props.subtitle}</h6>
          <img width="100%" height="234" src={picArr[0]} />
        </div>
      </li>
    </>
  );
}

export default Card;
