import "./Welkom.scss";
import Animation from "./Animation";
import { useMediaQuery } from "react-responsive";

function Welkom() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      <div className="welkom page">
        <h1>Welkom bij het bezoekerscentrum Midden-Delfland</h1>
        <p>Maak een keuze voor meer informatie</p>

        {!isTabletOrMobile ? <Animation /> : null}
      </div>
    </>
  );
}

export default Welkom;
