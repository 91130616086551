import Api from "../../../../../services/Api";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Swiper, SwiperSlide, Lazy } from "swiper/react";
import ReactPlayer from "react-player";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { useMediaQuery } from "react-responsive";

import FadeIn from "react-fade-in";
import "swiper/css";
import "./CardDetail.scss";
import ReactGA from "react-ga";

const NodeItem = ({ title, body, subtitle, media, url }) => {
  let picArr = [];
  let videoArr = [];
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  media.map((item) => {
    if (item) {
      if (item.url.match(".(png|jpeg|jpg|JPG)$")) {
        picArr.push(
          "https://redactie.middendelflandinbeeld.nl/files" + item.url.slice(46)
        );
      }
      if (item.url.match(".+.mp4")) {
        videoArr.push(
          "https://redactie.middendelflandinbeeld.nl/files" + item.url.slice(46)
        );
      }
    }
  });

  let sliderItemPic = picArr.map((item, index) => (
    <SwiperSlide key={index}>
      {
        <LazyLoadImage
          effect="opacity"
          className="img-slider"
          src={item}
          width="910"
          height="540"
        />
      }
    </SwiperSlide>
  ));

  let sliderItemVideo = videoArr.map((item, index) => (
    <SwiperSlide key={index}>
      <ReactPlayer
        width={910}
        className="video-slider"
        height={540}
        controls={true}
        light={false}
        url={item}
        pip={0}
        config={{
          file: {
            attributes: {
              disablepictureinpicture: "",
              controlsList: "nodownload noplaybackrate",
            },
          },
        }}
      />
    </SwiperSlide>
  ));
  let titlePage = parse("<h1 class=page-title>" + title + "</h1>");

  return (
    <FadeIn>
      {!isTabletOrMobile ? titlePage : null}
      {parse("<p class=page-subtitle>" + subtitle + "</p>")}
      <Swiper
        breakpoints={{
          250: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          400: {
            slidesPerView: 1,
            spaceBetween: -10,
          },
          600: {
            slidesPerView: 1,
            spaceBetween: -200,
          },
          800: {
            slidesPerView: 1,
            spaceBetween: -300,
          },
          900: {
            slidesPerView: 1,
            spaceBetween: -500,
          },
          1200: {
            slidesPerView: 1,
            spaceBetween: 500,
          },
          1440: {
            slidesPerView: 1,
            spaceBetween: -225,
          },
          1680: {
            slidesPerView: 1,
            spaceBetween: -300,
          },
          1700: {
            slidesPerView: 1,
            spaceBetween: -300,
          },
          1800: {
            slidesPerView: 1,
            spaceBetween: 500,
          },
          2000: {
            slidesPerView: 1,
            spaceBetween: -600,
          },
          2200: {
            slidesPerView: 1,
            spaceBetween: -700,
          },

          2500: {
            slidesPerView: 1,
            spaceBetween: -800,
          },
        }}
        slidesPerView={1}
        lazy={true}
        effect={blur}
        loadPrevNext={true}
      >
        <div>{sliderItemVideo}</div>
        {sliderItemPic}
      </Swiper>
      {parse(
        "<div class='page-text'>" +
          body
            .replaceAll(
              "/sites/redactie.middendelflandinbeeld.nl/files/",
              "https://redactie.middendelflandinbeeld.nl/files/"
            )
            .replaceAll(
              "<video",
              "<video disablepictureinpicture controlslist='nodownload noplaybackrate' "
            ) +
          "</div>"
      )}
      {url && isTabletOrMobile ? (
        <div className="route-link">
          <a href={url}>Volg deze route</a>
        </div>
      ) : null}
    </FadeIn>
  );
};

const NoData = () => <div></div>;

function CardDetail(props) {
  const [cardOutput, setCardOutput] = useState(false);
  const [media, setMedia] = useState();

  let taxName = "";

  props.status.menuMain == "Routes" ? (taxName = "route") : "";
  props.status.menuMain == "Locaties" ? (taxName = "locatie") : "";

  useEffect(() => {
    Api.fetchCard(taxName, props.id).then((data) => {
      setCardOutput(data.data);
      setMedia(data.included.map((term) => term.attributes.uri));
    });
    ReactGA.event({
      category: props.status.menuSub,
      value: props.status.page,
      label: props.status.page,
    });
  }, [props.status.buttonId.id]);

  return (
    <div className="page">
      {cardOutput ? (
        <NodeItem
          key={cardOutput.id}
          body={
            cardOutput.attributes.body
              ? cardOutput.attributes.body.processed
              : ""
          }
          title={cardOutput.attributes.title}
          subtitle={
            cardOutput.attributes.field_subtitel
              ? cardOutput.attributes.field_subtitel
              : ""
          }
          media={media ? media : []}
          url={props.status.url}
        />
      ) : (
        <NoData />
      )}
    </div>
  );
}

export default CardDetail;
