import { useEffect, useState } from "react";

import Api from "../../../../services/Api";
import ReactPlayer from "react-player";
import "swiper/css";
import "./Videos.scss";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useMediaQuery } from "react-responsive";

const NoData = () => <div></div>;

const NodeItem = ({ video, title }) => {
  let VideoArr = [];
  let VideoTitleArr = [];
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  video.map((item) => {
    if (item) {
      if (item.url.match(".+.mp4")) {
        VideoArr.push(
          "https://redactie.middendelflandinbeeld.nl/files" + item.url.slice(46)
        );
      }
    }
  });

  title.map((item) => {
    if (item) {
      VideoTitleArr.push(item);
    }
  });

  const [url, setUrl] = useState(VideoArr[0]);

  function ActiveVideo() {
    return (
      <ReactPlayer
        width={"100%"}
        height={"100%"}
        className={"big-video"}
        controls={true}
        url={url}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload noplaybackrate",
              disablepictureinpicture: "",
            },
          },
        }}
      />
    );
  }
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };
  let active = ActiveVideo();
  let videoItem = VideoTitleArr.map((title, index) => {
    let video = VideoArr[index];
    return (
      <>
        <div
          onClick={handleToggle}
          className={video == url ? "active video-card" : "inactive video-card"}
        >
          <h2>{title}</h2>
          <div className="video">
            <ReactPlayer
              onClick={() => setUrl(VideoArr[index])}
              width={250}
              height={128}
              controls={false}
              url={video}
              progressInterval={10}
            />
          </div>
        </div>
      </>
    );
  });
  let videoItemMobile = VideoTitleArr.map((title, index) => {
    let video = VideoArr[index];
    return (
      <div className="video-mobile-card" key={index}>
        <h2>{title}</h2>
        <div className="video">
          <ReactPlayer
            width={"100%"}
            height={215}
            controls={true}
            url={video}
            progressInterval={10}
            lazy={true}
          />
        </div>
      </div>
    );
  });

  return (
    <FadeIn>
      <div className="video-page">
        {!isTabletOrMobile ? <div className="big-video">{active}</div> : null}
        <div className="video-row">
          {isTabletOrMobile ? videoItemMobile : videoItem}
        </div>
      </div>
    </FadeIn>
  );
};

function Videos() {
  const [video, setVideos] = useState(false);
  const [videoTitle, setVideoTitle] = useState(false);

  useEffect(() => {
    Api.fetchVideos().then((data) => {
      setVideoTitle(data.data.map((term) => term.attributes.title));
      setVideos(data.included.map((term) => term.attributes.uri));
    });
  }, []);

  return video ? (
    <NodeItem key={video[0]} video={video} title={videoTitle} />
  ) : (
    <NoData />
  );
}

export default Videos;
