import "./Button.scss";
function Button(props) {
  let buttonName = props.label;
  const active =
    props.activeMenu && props.activeMenu !== buttonName ? "inactive " : "";
  let strippedName = buttonName.replace(/[\W_]+/g, "-");
  return (
    <li
      onClick={() => props.clickHandler(buttonName, props.level, props.id)}
      className={active + strippedName}
    >
      <span className="button-title">{props.label}</span>
    </li>
  );
}

export default Button;
