export default class Api {
  static fetchInfoPage() {
    return this._fetchApi(
      "node/pagina/2391791f-23c0-429b-982e-cb4c880b0e4b" +
        "?include=field_foto.field_media_image&fields[file--file]=uri,url"
    );
  }

  static fetchVideos() {
    return this._fetchApi(
      "node/video?include=field_video.field_media_video_file&fields[file--file]=uri,url"
    );
  }

  static fetchTax(tax) {
    return this._fetchApi("taxonomy_term/" + tax);
  }

  static fetchCardslist(tax, id) {
    return this._fetchApi(
      "node/" +
        tax +
        "?include=field_type_" +
        tax +
        "&filter[field_type_" +
        tax +
        ".id][value]=" +
        id
    );
  }

  static fetchCard(tax, id) {
    return this._fetchApi(
      "node/" +
        tax +
        "/" +
        id +
        "?include=field_foto.field_media_image,field_foto.field_media_video_file&fields[file--file]=uri,url"
    );
  }

  static _fetchApi = (query, $includes = "") => {
    const API_ROOT = "https://redactie.middendelflandinbeeld.nl/jsonapi/";
    const url = `${API_ROOT}${query}${$includes}`;
    const headers = new Headers({
      Accept: "application/vnd.api+json",
    });

    return fetch(url, { headers })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(() => "There was an error accessing the API");
  };
}
