import MenuMain from "./MenuMain/MenuMain";
import Welkom from "../Main/Welkom/Welkom";
import "./Sidebar.scss";
import { useMediaQuery } from "react-responsive";

import QRCode from "react-qr-code";
import Logo from "./Logo/Logo";

function Sidebar(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  if (isTabletOrMobile) {
    return (
      <div className="sidebar">
        <Logo clickHandler={props.backHomeButton} status={props.status} />
        {props.status.menuMain == "" ? (
          <>
            <Welkom />
            <MenuMain
              list={props.list}
              status={props.status}
              clickHandler={props.clickHandler}
            />
          </>
        ) : null}
      </div>
    );
  }
  if (!props.status.page) {
    return (
      <div className="sidebar">
        <Logo clickHandler={props.backHomeButton} status={props.status} />
        <MenuMain
          list={props.list}
          status={props.status}
          clickHandler={props.clickHandler}
        />
      </div>
    );
  }

  return (
    <>
      <div className={isTabletOrMobile ? "mobile-sidebar" : "qr-sidebar"}>
        <Logo clickHandler={props.backButton} status={props.status} />
        {props.status.url && !isTabletOrMobile ? (
          <div className="qr-code">
            <p>
              Scan de QR-code met de camera van uw telefoon voor deze locatie of
              route.
            </p>
            <QRCode
              value={props.status.url ? props.status.url : ""}
              size={210}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Sidebar;
