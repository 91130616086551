import ListCategories from "./ListCategories/ListCategories";
import CardsList from "./ListCategories/CardsList/CardsList";
import Welkom from "./Welkom/Welkom";
import InfoPage from "./InfoPage/Info";
import CardDetail from "./ListCategories/CardDetail/CardDetail";
import Videos from "./Videos/Videos";
import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga";

import BackImage from "../../../assets/terug.svg";
function Main(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  function activeClass() {
    switch (props.status.menuMain) {
      case "Routes":
        return "Routes main";
      case "Locaties":
        return "Locaties main";
      case "Agenda":
        return "Agenda main";
      case "Videos":
        return "Video-page main";
      case "Midden Delfland in Beeld":
        return "Midden-Delfland-in-Beeld main";
      default:
        return "main";
    }
  }
  ReactGA.event({
    category: props.status.menuMain,
    action:props.status.menuMain,
    value: props.status.page,
    label: props.status.menuSub,
  });
  if (isTabletOrMobile && props.status.menuMain == "") {
    return null;
  }
  return (
    <div className={activeClass()}>
      {isTabletOrMobile && !props.status.page ? (
        <div className="mobile-back-wrapper">
          <div onClick={() => props.backButton()} className="mobile-back">
            <div className="back">
              <img src={BackImage} alt="Terug" />
            </div>
          </div>
          <div className="mobile-title">
            {props.status.menuSub
              ? props.status.menuSub
              : props.status.menuMain}
          </div>
        </div>
      ) : null}
      {(props.status.menuMain == "Routes" ||
        props.status.menuMain == "Locaties") &&
      props.status.page == "" ? (
        <>
          <ListCategories
            status={props.status}
            level="menuSub"
            clickHandler={props.menuSelector}
            backButton={props.backButton}
          />
        </>
      ) : null}

      {props.status.menuSub && props.status.page == "" ? (
        <>
          <CardsList
            key={props.status.buttonId.id}
            status={props.status}
            clickHandler={props.page}
            cardBuilder={props.cardBuilder}
          />
        </>
      ) : null}

      {props.status.page ? (
        <>
          {isTabletOrMobile ? (
            <div className="mobile-back-wrapper">
              <div onClick={() => props.backButton()} className="mobile-back">
                <div className="back">
                  <img src={BackImage} alt="Terug" />
                </div>
              </div>
              <div className="mobile-title">{props.status.page}</div>
            </div>
          ) : null}
          <CardDetail
            title={props.status.page}
            status={props.status}
            id={props.status.pageId}
            routeUrl={props.routeUrl}
          />
        </>
      ) : null}

      {props.status.menuMain === "Midden Delfland in Beeld" ? (
        <InfoPage />
      ) : null}
      {props.status.menuMain === "" && !isTabletOrMobile ? <Welkom /> : null}
      {props.status.menuMain === "Videos" ? <Videos /> : null}
    </div>
  );
}

export default Main;
