import { useEffect, useRef } from "react";
import lottie from "lottie-web";

import Touchscreen from "../../../../assets/Touchscreen.json";

const Animation = () => {
  const anime = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: Touchscreen,
    });
    return () => lottie.stop();
  }, []);
  return (
    <div
      className="animation"
      style={{ height: 520, width: 300 }}
      ref={anime}
    ></div>
  );
};

export default Animation;
