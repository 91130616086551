import { useEffect, useState } from "react";

import "./Info.scss";
import Api from "../../../../services/Api";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";
import FadeIn from "react-fade-in/lib/FadeIn";

const NoData = () => <div></div>;

const NodeItem = ({ body, title, field_colofon, pics }) => {
  let picArr = [];
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  pics.map((pic) => {
    if (pic) {
      picArr.push(
        "<div class=logo-info>" +
          "<img src=https://redactie.middendelflandinbeeld.nl/files" +
          pic.url.slice(46) +
          " class=" +
          pic.url.slice(57, -4) +
          ">" +
          "</div>"
      );
    }
  });
  let titlePage = parse("<h1 class=page-title>" + title + "</h1>");

  return (
    <>
      {!isTabletOrMobile ? titlePage : null}
      <FadeIn>
        {parse(
          "<div class='info'>" +
            "<div class='page-text'>" +
            body.processed +
            "</div>" +
            "</div>" +
            "<div class='colofon'>" +
            field_colofon.processed +
            "<div class='logos'>" +
            picArr.slice(0, -5).join(" ") +
            "</div>" +
            "<p>Midden-Delfland in Beeld word mede mogelijk gemaakt door: </p>" +
            "<div class='logos'>" +
            picArr.slice(2, -1).join("") +
            "</div>" +
            "<div class='europa'>" +
            picArr.slice(-1) +
            "<p>Dit project is mede mogelijk gemaakt door Europees Landbouwfonds voor Plattelandsontwikkeling. Europa investeert in zijn platteland. </p>" +
            "</div>" +
            "</div>"
        )}
      </FadeIn>
    </>
  );
};
function InfoPage() {
  const [content, setContent] = useState(false);
  const [pic, setPic] = useState();

  useEffect(() => {
    Api.fetchInfoPage().then((data) => {
      setContent(data.data);
      setPic(data.included.map((term) => term.attributes.uri));
    });
  }, []);

  return (
    <div className="infopage">
      {content ? (
        <NodeItem
          key={content.id}
          {...content.attributes}
          {...content.included}
          pics={pic}
        />
      ) : (
        <NoData />
      )}
    </div>
  );
}

export default InfoPage;
