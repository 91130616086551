import "./CardsList.scss";
import { useEffect, useState } from "react";
import Api from "../../../../../services/Api";
import Card from "./Card/Card";
import Loader from "react-spinners/MoonLoader";
import FadeIn from "react-fade-in";

function CardsList(props) {
  const [isLoading, setLoading] = useState(true);

  const [cardsOutput, setCardsOutput] = useState([[], []]);
  let taxName = "";

  if (props.status.menuMain == "Routes") {
    taxName = "route";
  }
  if (props.status.menuMain == "Locaties") {
    taxName = "locatie";
  }

  useEffect(() => {
    Api.fetchCardslist(taxName, props.status.buttonId.id).then((data) => {
      setLoading(false);
      setCardsOutput(
        data.data.map((term) => [
          term.id,
          term.attributes.title,
          term.attributes.field_subtitel,
          term.attributes.field_url,
        ])
      );
    });
  }, [props.status.buttonId.id]);

  const listCards = cardsOutput.map((card) => (
    <Card
      key={card[0]}
      id={card[0]}
      clickHandler={props.clickHandler}
      cardBuilder={props.cardBuilder}
      title={card[1]}
      subtitle={card[2]}
      url={card[3]}
      tax={taxName}
    />
  ));
  return isLoading ? (
    <div className="loading-screen">
      <Loader color={"#ffffff"} loading={isLoading} size={50} />
    </div>
  ) : (
    <div className="cardslist">
      <FadeIn>
        <ul className="cards">{listCards}</ul>
        {listCards != "" ? <p className="end">Einde van de lijst</p> : ""}
      </FadeIn>
    </div>
  );
}
export default CardsList;
