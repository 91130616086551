import { Component } from "react";

import "./Kiosk.scss";
import Sidebar from "./Sidebar/Sidebar";
import Main from "./Main/Main";

class Kiosk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {
        menuMain: "",
        menuSub: "",
        menuSubSub: "",
        page: "",
        url: "",
        buttonId: {
          id: "",
        },
        pageId: "",
      },
      sidebarList: [
        ["Routes", 0],
        ["Locaties", 1],
        // ["Agenda", 2],
        ["Videos", 3],
        ["Midden Delfland in Beeld", 4],
      ],
    };
  }

  cardBuilder = (id, title, url) => {
    this.setState({
      status: {
        ...this.state.status,
        pageId: id,
        page: title,
        url: url,
      },
    });
  };

  menuSelector = (name, level, id) => {
    if (name == this.state.status[level]) {
      name = "";
    }

    if (level == "menuMain") {
      this.setState({
        status: {
          ...this.state.status,
          menuMain: name,
          menuSub: "",
          menuSubSub: "",
          page: "",
          buttonId: {
            id: "",
          },
        },
      });
    }
    if (level == "menuSub") {
      this.setState({
        status: {
          ...this.state.status,
          menuSub: name,
          menuSubSub: "",
          page: "",
          buttonId: {
            id: id,
          },
        },
      });
    }
    if (level == "menuSubSub") {
      this.setState({
        status: {
          ...this.state.status,
          menuSubSub: name,
          page: "",
        },
      });
    }
    if (level == "page") {
      this.setState({
        status: {
          ...this.state.status,
          page: name,
        },
      });
    }
  };
  backHomeButton = () => {
    this.setState({
      status: {
        menuMain: "",
        menuSub: "",
        menuSubSub: "",
        buttonId: {
          id: "",
        },
      },
    });
  };

  backButton = () => {
    this.setState({
      status: {
        ...this.state.status,
        menuMain: "",
        menuSub: "",
        menuSubSub: "",
        buttonId: {
          id: "",
        },
      },
    });
    if (this.state.status.menuSub) {
      this.setState({
        status: {
          ...this.state.status,
          menuSub: "",
        },
      });
    }
    if (this.state.status.page) {
      this.setState({
        status: {
          ...this.state.status,
          page: "",
        },
      });
    }
  };
  loadPage = (page) => {
    this.setState({
      status: {
        ...this.state.status,
        page: page,
      },
    });
  };

  render() {
    return (
      <div className="kiosk">
        <Sidebar
          list={this.state.sidebarList}
          status={this.state.status}
          clickHandler={this.menuSelector}
          backHomeButton={this.backHomeButton}
          backButton={this.backButton}
        />
        <Main
          menuSelector={this.menuSelector}
          backButton={this.backButton}
          page={this.loadPage}
          status={this.state.status}
          cardBuilder={this.cardBuilder}
        />
      </div>
    );
  }
}

export default Kiosk;
