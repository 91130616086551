import Button from "./Button";

function ButtonList(props) {
  const listButtons = props.buttons.map((button) => (
    <Button
      key={button[1]}
      label={button[0]}
      activeMenu={props.activeMenu}
      clickHandler={props.clickHandler}
      level={props.level}
      id={button[1]}
    />
  ));
  return <ul className="buttons">{listButtons}</ul>;
}

export default ButtonList;
