import ButtonList from "../../../shared/ButtonList";
import { useMediaQuery } from "react-responsive";

function MenuMain(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return props.status.menuMain && isTabletOrMobile ? null : (
    <ButtonList
      activeMenu={props.status.menuMain}
      buttons={props.list}
      clickHandler={props.clickHandler}
      level="menuMain"
    />
  );
}

export default MenuMain;
