import { useEffect, useState } from "react";

import Api from "../../../../services/Api";
import ButtonList from "../../../shared/ButtonList";
import "./ListCategories.scss";
import FadeIn from "react-fade-in";
import { useMediaQuery } from "react-responsive";

function ListCategories(props) {
  const [isLoading, setLoading] = useState(true);

  const [tax, setTax] = useState(false);

  useEffect(() => {
    let taxName = "";

    if (props.status.menuMain == "Routes") {
      taxName = "route";
    }
    if (props.status.menuMain == "Locaties") {
      taxName = "locaties";
    }
    Api.fetchTax(taxName).then((data) => {
      setLoading(false);
      const listButtons = data.data.map((term) => [
        term.attributes.name,
        term.id,
      ]);
      setTax(listButtons);
    });
  }, [props.status.menuMain]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div className={props.level}>
      {isLoading ? (
        ""
      ) : (
        <>
          {props.status.menuSub && isTabletOrMobile ? null : (
            <FadeIn>
              <ButtonList
                activeMenu={props.status[props.level]}
                buttons={tax}
                level={props.level}
                clickHandler={props.clickHandler}
              />
            </FadeIn>
          )}
        </>
      )}
    </div>
  );
}

export default ListCategories;
